import React from 'react';
import { Spoiler, Table } from '@mantine/core';
import Layout from '../../components/Layout';
import blogd1 from '../../assets/images/2024/marks-years-six-project/1.jpeg';
import blogd2 from '../../assets/images/2024/marks-years-six-project/2.jpeg';
import blogd3 from '../../assets/images/2024/marks-years-six-project/3.jpeg';
import blogd4 from '../../assets/images/2024/marks-years-six-project/4.jpeg';
import blogd5 from '../../assets/images/2024/marks-years-six-project/5.jpeg';
import blogd6 from '../../assets/images/2024/marks-years-six-project/6.jpeg';
import blogd7 from '../../assets/images/2024/marks-years-six-project/7.jpeg';
import blogd8 from '../../assets/images/2024/marks-years-six-project/8.jpeg';
import blogd9 from '../../assets/images/2024/marks-years-six-project/9.jpeg';
import blogd10 from '../../assets/images/2024/marks-years-six-project/10.jpeg';

import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';

const NewsDetails = () => {
  const subs = [
    {
      SNO: '1',
      PROJECTS: 'Luth Central Laboratory',
    },
    {
      SNO: '2',
      PROJECTS: 'LUTH Cafeteria',
    },
    {
      SNO: '3',
      PROJECTS: 'Urology (K-block) Theatre',
    },
    {
      SNO: '4',
      PROJECTS: 'Ward E4',
    },
    {
      SNO: '5',
      PROJECTS: 'Ward E6',
    },
    {
      SNO: '6',
      PROJECTS: 'Private ward(Ward E8)',
    },
  ];

  const subRows = subs.map((element) => (
    <tr key={element.SNO}>
      <td>{element.SNO}</td>
      <td>{element.PROJECTS}</td>
    </tr>
  ));

  return (
    <section className='blog-details'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='blog-one__single'>
              <div className='blog-one__image'>
                <img src={blogd1} alt='' />
              </div>
              <div className='blog-one__content text-center'>
                <h2 className='blog-one__title'>
                  LUTH CHIEF MEDICAL DIRECTOR, PROF. WASIU LANRE ADEYEMO MARKS A
                  YEAR IN OFFICE: COMMISSIONS SIX PROJECTS
                </h2>
                <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  Lagos University Teaching Hospital Chief Medical director,
                  Professor. Wasiu Lanre Adeyemo, FAS had a significant event to
                  mark his one year in office by commissioning 6 projects aimed
                  at enhancing the infrastructure and services offered by the
                  hospital. The commissioning ceremony celebrated the
                  culmination of efforts to improve the hospital's facilities
                  and capabilities.
                  <br />
                  The following projects were commissioned;
                  <br />
                  <Table fontSize='md' striped style={{ textAlign: 'left' }}>
                    <thead>
                      <tr>
                        {/* <th>SNO</th> */}
                        {/* <th>SPEAKERS</th> */}
                        {/* <th>SESSION</th> */}
                      </tr>
                    </thead>
                    <tbody>{subRows}</tbody>
                  </Table>
                  <br />
                  The commissioning of these projects underscores the hospital's
                  commitment to continuous improvement and excellence in
                  healthcare delivery. These endeavors are poised to positively
                  impact patient outcomes, enhance operational efficiency, and
                  contribute to the overall well-being of the hospital
                  community.
                  <br />
                </p>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd2} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd3} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* Consultative Services Ongoing at the Outreach */}
                </span>
                {/* The second speaker Dr. Ifedayo odeniyi, Associate professor of
                medicine and consultant endocrinologist, LUTH.
                <br />
                Dr. Ifedayo discussed the financial strain and difficulties
                associated with Diabetes mellitus in Nigeria, he highlighted the
                direct and indirect costs of diabetes mellitus in Nigeria.
                Additionally, the loss of productivity and disability added to
                the financial burden on individuals and families.
                <br />
                He outlined several challenges in providing effective diabetes
                care in Nigeria:
                <br />
                Lack of education and awareness about treatment, Insufficient
                government support and healthcare facilities, High costs of
                healthcare services and medicines, Shortage of trained
                healthcare workers and Cultural beliefs and socioeconomic
                factors affecting insulin use and monitoring. */}
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd4} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {/* attendees at the seminar */}
                </span>
                {/* <table style='width:100%'>
                  <tr>
                    <th>Date</th>
                    <th>Activities</th>
                    <th>Venue</th>
                  </tr>
                  <tr>
                    <td>Tuesday, 1st August</td>
                    <td>Webinar</td>
                    <td>@3:00pm</td>
                  </tr>
                  <tr>
                    <td>Wednesday, 2nd August, 2023</td>
                    <td>Awareness Creation in LUTH
                        <br />
                        Presentation by NANPAN LUTH @ Nurse's Seminar Room
                    </td>
                    <td>@ 8:30am
                        <br />
                        @11:00am
                    </td>
                  </tr>
                  <tr>
                    <td>Thursday, 3rd August</td>
                    <td>Train the trainers on BFHI @ Paediatrics Department</td>
                    <td>@9:00am - 2:00pm</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@9:00am - 12:00noon</td>
                  </tr>
                  <tr>
                    <td>Friday, 4th August</td>
                    <td>Outreach Program at Seriki's Compound, Haruna Street, Idi-Araba</td>
                    <td>@1:00pm</td>
                  </tr>
                  <tr>
                    <td>Sunday, 6th August</td>
                    <td>Health Talk @ LUTH/CMUL Chaplaincy, Idi-Araba</td>
                    <td>@9:30am</td>
                  </tr>
                </table> */}
                {/* <p className='blog-one__text' style={{ marginBottom: 30 }}>
                  He suggested several ways to tackle these challenges:
                  <br />
                  Educating the public through health campaigns, Improving
                  healthcare facilities and services, Making treatments more
                  affordable and accessible, Training healthcare workers and
                  Advocating for policy changes to remove barriers to care.
                  <br />
                  Dr. Ifedayo concluded with Challenges of diabetes care in
                  Nigeria are myriad <br />
                  •High economic burden of diabetes mellitus
                  <br />
                  •High catastrophic expenditure
                  <br />
                  <br />
                  Dr. Dr. Nelson Aluya concluded the presentation by discussing
                  the significance of global humanitarian interventions in
                  addressing resource-challenged environments, particularly
                  focusing on the global epidemic of Diabetes Mellitus and
                  strategies for its management and prevention. He emphasized
                  the importance of learning from developed countries to inform
                  interventions in developing nations. He underscored the
                  urgency of addressing Diabetes Mellitus as a global epidemic,
                  emphasizing the need for comprehensive strategies to manage
                  and prevent its adverse effects.
                  <br />
                  He outlined key management strategies for Diabetes Mellitus,
                  emphasizing the importance of lifestyle modifications,
                  including dietary changes, regular exercise, and weight
                  management. Additionally, Dr. Nelson highlighted the
                  significance of medication adherence, regular monitoring, and
                  access to quality healthcare services in mitigating the
                  disease burden.
                </p> */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd5} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd6} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd7} height={370} />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-6'>
                    <img src={blogd8} height={370} />
                  </div>
                  <div className='col-lg-6'>
                    <img src={blogd9} height={370} />
                  </div>
                </div>
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  Sections of the Seminar
                </span>
                <div className='row'>
                  <div className='col-lg-12'>
                    <img src={blogd10} height={370} />
                  </div>
                </div>
                {/* <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd11} height={370} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd12} height={370} />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <img src={blogd13} height={370} />
                </div>
              </div> */}
                <span style={{ fontSize: 14, fontStyle: 'italic' }}>
                  More pictures
                </span>
              </div>
            </div>
            <div className='share-block'>
              <div className='left-block'>
                <p>
                  Tags: <a href='#none'></a>
                </p>
              </div>
              {/* <div className='social-block'>
              <a href='#none'>
                <i className='fab fa-twitter'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-facebook-f'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-instagram'></i>
              </a>
              <a href='#none'>
                <i className='fab fa-dribbble'></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const GalleryPage = () => {
  return (
    <Layout pageTitle='LAGOS UNIVERSITY TEACHING HOSPITAL SPECIAL GRAND ROUND  | LUTH News'>
      <NavOne />
      <PageHeader title='' />
      <NewsDetails />
      <Footer />
    </Layout>
  );
};

export default GalleryPage;
